import { Component, OnInit } from '@angular/core';

// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

import * as pdfMakeConfig from 'pdfmake/build/pdfmake.js';
import * as pdfFontsX from 'pdfmake/build/vfs_fonts';
pdfMakeConfig.vfs = pdfFontsX.pdfMake.vfs;
import * as pdfMake from 'pdfmake/build/pdfmake';

import { server, ToFixed, verificaDuplicadoWeb } from "../globals";
import Swal from "sweetalert2";

import { CompleterService, CompleterCmp, CompleterItem, RemoteData } from 'ng2-completer';

import { MultilanguageService } from "app/multilanguage.service";


@Component({
  selector: 'app-presupuesto-puntual',
  templateUrl: './presupuesto-puntual.component.html',
  styleUrls: ['./presupuesto-puntual.component.css']
})
export class PresupuestoPuntualComponent implements OnInit {
  // public nombreHq: string;
  public CodigoPostal: string;
  public Region: string;
  public Empresa: string = "";
  public Contacto: string = "";
  public Direccion: string = "";
  public Telefono: string = "";
  public Email: string = "";
  public Peticion: string = "";

  public DatosValidos = 'disabled';
  protected ZipCodeOrCityDataService: RemoteData;

  public HorasDia = 0; //4;
  public CantidadPersonas = 0; //1;

  public PrecioFinalHora = 0;
  public TotalSemanal = 0;
  public TotalMensual = 0;

  public Base = 13;
  public CostoDesplazamiento = 4;
  public CostoMaterial = 0;
  public CostoMaterialTmp = 0;
  public TipoLimpieza = 1;
  public Metros = 0;
  public RequiereMaterial = false;
  public LimiteMetros = 110;
  public AumentoPorMetros = 2.5;
  public RangoAumento = 20;
  public Disabled: boolean= false;
  public llv: any;
  public logo = "";

  public contenido: any;

  constructor(private completerService: CompleterService, private _multilanguage: MultilanguageService) {
    this.CreateCompleterServices();
  }



  async ngOnInit() {
    this._multilanguage.obtenerContenido("inicio")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))

    this.CalcularTotal()
    console.log("antes del base64")
    this.convertImgToBase64URL("../../assets/Img/foxlogo.jpg", "JPG")
    console.log("despues del base64")

    let req = await fetch(server + "api/datos_presupuesto_puntual")
    let result = await req.json()

    this.Base = Number(result.precio_base)
    this.CostoDesplazamiento = Number(result.desplazamiento)
    this.CostoMaterialTmp = Number(result.material)
    this.LimiteMetros = Number(result.limite_metros)
    this.AumentoPorMetros = Number(result.precio_aumento_por_metros)
    this.RangoAumento = Number(result.a_cada_cuanto_aumento_por_metros)
  }

  cambio() {
    console.log('dis que cambio... ', this.Empresa)
  }

  async CreateCompleterServices() {
    let CreateUrl = (Query: string, Fields: string[], OrderBy: string[]): string =>
    {
      Query = encodeURIComponent(Query);
      const ApiService = `${server}api/Presupuesto/BudgetsByPostalCodes?`;
      return `${ApiService}Query=${Query}&Fields=${Fields.join(",")}&OrderBy=${OrderBy.join(",")}`;
    };

    this.ZipCodeOrCityDataService = this.completerService.remote(null, "CodigoPostal,Provincia,Poblacion", "CityFullName");
    this.ZipCodeOrCityDataService.urlFormater((Term: any) => {
      return CreateUrl(Term, ["CodigoPostal", "Provincia", "Poblacion"], ["Provincia", "Poblacion", "CodigoPostal"]);
    });
  }

  CloseModal(ModalId: string): void {
    $(ModalId).modal("hide");
  }

  async generarPDF() {
    this.Disabled=true
    this.CalcularTotal()
    if (this.Empresa == "" || this.Contacto == "" || this.Direccion == "" || this.Telefono == "" || this.Email == "" || this.Peticion == "") {
      Swal.fire({
        type: 'warning',
        title: 'FoxClean',
        html: "Por favor complete todos los campos"
      });
      return
    }

    this.Peticion += `
    Empresa: ${this.Empresa}
    Contacto: ${this.Contacto}
    Dirección: ${this.Direccion}

    Precio final hora: €${this.PrecioFinalHora.toFixed(2)}
    Horas por persona: ${this.HorasDia}
    Personas: ${this.CantidadPersonas}
    Material: ${this.CostoMaterial}
    Total: €${this.TotalSemanal}

    Metros Cuadrados: ${this.Metros}
    `
    //AQUI DEBO EVALUAR SI YA EXISTE REGISTRO EN EL DIA QUE COINCIDA CON CAMPOS
    // --> INICIO
    let data = {
      "Empresa": this.Empresa,
      "Nombre": this.Contacto,
      "Apellido": "",
      "Ciudad": this.CodigoPostal + " - " + this.Region,
      "Telefono": this.Telefono,
      "Mail": this.Email,
      "Peticion": "PETICION DE PRESUPUESTO PUNTUAL FOXCLEAN.ES \n" + this.Peticion,
      "fecha": new Date().toISOString().substr(0, 10)
    }

    let datatmp = data
    datatmp['opcion'] = "Presupuesto"
    console.log('evaluacion de registro para evitar repetidos y busca trabajo')
    // let req = await verificaDuplicadoWeb(JSON.stringify(datatmp))
    await verificaDuplicadoWeb(JSON.stringify(datatmp)).then((req)=>{
      console.log('req then', req)
      //analizando resultado, dependiendo continuamos o cancelamos ejecución
      if(req.lookingForAJob>0){
        // alert('Para buscar trabajo hazlo en Bolsa de trabajo.')
        this.CloseModal("#exampleModal1");
        //this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);
        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          showCloseButton: true,
          html: '<b>Para buscar trabajo hazlo en <br />Bolsa de tabajo.</b>'
        });
  
        return
      }
      if(req.rows>0){
        // alert('Tus datos fueron almacenados correctamente.')
        this.CloseModal("#exampleModal1");
        Swal.fire({
          type: 'success',
          title: 'FoxClean',
          showCloseButton: true,
          html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>'
        });
        return
      }      
    })
    console.log('ln 171')
    let req

    //alert('Error, verificar')
    //rows: 0, lookingForAJob: 1

    //temporal
    //return
    
     let options = { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: "admin@foxclean.net", subject: "Petición Presupuesto Puntual", message: this.Peticion }) }
     req = await fetch(server + "api/SendEmail", options)

     data = {
      "Empresa": this.Empresa,
      "Nombre": this.Contacto,
      "Apellido": "",
      "Ciudad": this.CodigoPostal + " - " + this.Region,
      "Telefono": this.Telefono,
      "Mail": this.Email,
      "Peticion": "PETICION DE PRESUPUESTO PUNTUAL FOXCLEAN.ES \n" + this.Peticion,
      "fecha": new Date().toISOString().substr(0, 10)
    }

    options = { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(data) }
    req = await fetch(server + "api/Clientes/NuevaPeticionLLV", options)
    this.llv = await req.text()

    let pdf = {
      content: [
        { image: this.logo, width: 200, margin: [-40, -40, 10, 10] },
        { text: 'LIMPIEZA HIGIENE, MANTENIMIENTO GENERAL E INTEGRAL DE EMPRESAS Y COMUNIDADES.', style: 'header' },

        {
          columns: [
            [
              { text: `REFERENCIA: ${this.llv}`, style: 'body', bold: true, margin: [0, 0, 10, 30] },
              { text: `Fecha: ${new Date().toLocaleDateString()}`, style: 'body', bold: true, margin: [0, 0, 10, 30] },
            ],
            [
              {
                text: `${this.Empresa}
              At: ${this.Contacto}
              ${this.Direccion}
              `, style: 'body', bold: true
              },
            ]
          ]
        },


        {
          text: `El siguiente presupuesto tiene como objeto la prestación de los servicios de limpieza a fondo a ${this.PrecioFinalHora.toFixed(2)} euros + IVA la hora por una persona, para la limpieza a fondo. Las horas mínimas  por persona son  ${this.HorasDia} horas. Se enviarán ${this.CantidadPersonas} personas

        Costo de organización 20 euros

        Si desea se incluyan los materiales para la limpieza tendrá un incremento de ${this.CostoMaterial} euros. (Al culminar la limpieza los materiales se quedarán con usted).
        El total a cancelar serán las horas finales realizadas más el costo de materiales de limpieza si desea incluir estos.
        `, style: 'body'
        },
        { text: `Total: ${this.TotalSemanal}  € + IVA `, style: 'header' },

        {
          text: `El pago debe realizarse al culminar la limpieza o si desea puede realizar una transferencia de forma anticipada.`, style: 'body'
        },
        {
          text: `Si se necesitase un aspirador o algún otro equipo para a limpieza este se presupuestará aparte y se cobrará el total junto con lo ya calculado de la limpieza puntual.`, style: 'body'
        },

        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            // layout: 'lightHorizontalLines',
            widths: ['*', '*'],

            body: [
              [{ text: 'ACEPTACIÓN DE PRESUPUESTO:', colSpan: 2, style: 'body', fontSize: 10, bold: true, alignment: 'center' }],
              [{ text: 'RAZON SOCIAL: ', style: 'body', fontSize: 9 }, { text: 'CIF:', style: 'body', fontSize: 9 }],
              [{ text: 'DIRECCION DEL SERVICIO:', colSpan: 2, style: 'body', fontSize: 9 }],
              [{ text: 'DIRECCION DE FACTURACIÓN:', colSpan: 2, style: 'body', fontSize: 9 }],
              [{ text: 'TELEFONO: ', style: 'body', fontSize: 9 }, { text: 'EMAIL: ', style: 'body', fontSize: 9 }],
              [{ text: 'PERSONA QUE FIRMA ESTE PRESUPUESTO: ', colSpan: 2, style: 'body', fontSize: 9 }],
              [{ text: 'CARGO: ', style: 'body', fontSize: 9 }, { text: 'DNI: ', style: 'body', fontSize: 9 }],

            ]
          },

        },
        {
          columns: [
            [
              { text: 'Por la empresa', style: 'body', alignment: 'center' },
              {
                text: `
              JUAN CARLOS RUANO
              609256051

              `, style: 'body', bold: true, alignment: 'center'
              },
            ],
            [
              { text: 'Por el cliente', style: 'body', alignment: 'center' },
            ],
          ], margin: [0, 40, 0, 0]
        },

      ],
      styles: {
        header: {
          fontSize: 12,
          margin: [50, 30, 10, 50],
          bold: true,
          alignment: 'center'
        },
        body: {
          fontSize: 10,
          bold: false
        },
        link: {
          fontSize: 12,
          color: '#03A9F4'
        }
      }
    };

    await pdfMake.createPdf(pdf).open();
    setTimeout(() => {
      //location.reload();
      this.CloseModal("#exampleModal1");
      Swal.fire({
        type: 'success',
        title: 'FoxClean',
        showCloseButton: true,
        html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>'
      });
    }, 1000);
    this.Disabled=false
  }

  convertImgToBase64URL(url, outputFormat) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      var canvas: any = document.createElement('CANVAS'), ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      // callback(dataURL);
      this.logo = dataURL;
      canvas = null;
    };
    img.src = url;
  }

  CalcularTotal() {
    console.log("calculando material...")
    let subTotal = ((this.Base * this.HorasDia) + this.CostoDesplazamiento) / this.HorasDia
    this.PrecioFinalHora = subTotal < 11.5 ? 11.5 : subTotal;

    if (this.TipoLimpieza == 2) {
      this.PrecioFinalHora = this.PrecioFinalHora + (this.PrecioFinalHora * 20 / 100)
    }
    if (this.TipoLimpieza == 3) {
      this.PrecioFinalHora = this.PrecioFinalHora + (this.PrecioFinalHora * 30 / 100)
    }

    if (this.RequiereMaterial == true) {
      console.log("sí requiere material >>> ")
      this.CostoMaterial = this.CostoMaterialTmp;
      if (this.TipoLimpieza == 2) {
        this.CostoMaterial = 32;
      }
      if (this.TipoLimpieza == 3) {
        this.CostoMaterial = 34;
      }
      console.log("hasta el momento costo de material >>> ", this.CostoMaterial)

      if (this.Metros > this.LimiteMetros) {
        let q = this.Metros - this.LimiteMetros // diferencia de metros
        let r = Math.ceil(q / this.RangoAumento)
        this.CostoMaterial += r * this.AumentoPorMetros
      }
      console.log("al final costo de material >>> ", this.CostoMaterial)

    } else {
      this.CostoMaterial = 0;
      console.log("no requiere material >>> ", this.CostoMaterial)
    }
    
    this.TotalSemanal = ((this.PrecioFinalHora * this.HorasDia * this.CantidadPersonas) + this.CostoMaterial ) + 20;
    this.TotalSemanal = isNaN(this.TotalSemanal) ? 0.00: this.TotalSemanal;

      //this.TotalSemanal = this.TotalSemanal === NaN || this.TotalSemanal === null ? 0: this.TotalSemanal
    
    //this.TotalSemanal+20 //costo de organización
    //this.TotalMensual = this.TotalSemanal * 4.34;
  }

  AgregarPersonas(n) {
    this.CantidadPersonas += n;
    this.CantidadPersonas = this.CantidadPersonas < 1 ? 1 : this.CantidadPersonas
    // this.CantidadPersonas = this.CantidadPersonas > 7 ? 7 : this.CantidadPersonas

    this.CalcularTotal()
  }

  AgregarHoras(n) {
    this.HorasDia += n;
    this.HorasDia = this.HorasDia < 4 ? 4 : this.HorasDia
    this.HorasDia = this.HorasDia > 24 ? 24 : this.HorasDia

    this.CalcularTotal()
  }

  OnZipCodeOrCitySelected(Selected: CompleterItem) {
    if (Selected) {
      this.HorasDia = 4;
      this.CantidadPersonas = 1;
      this.CodigoPostal = Selected.originalObject.CodigoPostal;
      this.DatosValidos = '';
      this.CalcularTotal();
    }
    else {
      this.HorasDia = 0;
      this.CantidadPersonas = 0;
      this.TotalSemanal = 0;
      this.CodigoPostal = '';
      this.DatosValidos = 'disabled';
      this.CalcularTotal();
    }
  }
}
