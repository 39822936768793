"use strict";

import { environment } from "environments/environment";

/**
 * @description Url del servidor de peticiones
 */
export const server = environment.server;

/**
 * @description Simbolo de moneda usada por la aplicación
 */
export const SimboloMoneda = "€";

/**
 * @description Redondea una número indicando la cantidad de dígitos necesarios
 * @param Value {number} Número a redondear
 * @param Digits {number} Total de decimales del número
 */
export function ToFixed(Value: number, Digits = 2): number {
  return parseFloat(Value.toFixed(Digits));
}

/**
 * @description Verifica si una cadena es null, undefined o si esta vacía
 * @param Str {string} Cadena que se verificara
 */
String.IsNullOrEmpty = Str => Str === null || Str === undefined || Str.length === 0;

/**
 * @description Verifica si una cadena es null, undefined, vacía o si tiene espacios en blanco
 * @param Str {string} Cadena que se verificara
 */
String.IsNullOrWhiteSpace = Str => String.IsNullOrEmpty(Str) || Str.trim().length === 0;

/**
 * @description Regresa una cadena vacía
 */
String.Empty = () => "";

export async function WebLogs(Web: string, action: string) {
  let url = `https://foxservice.net/api/`;
  let request = await fetch(url);
  let respu = ''
  if (request.ok) {
    let contenido = await request.json();
    return contenido;
  } else {
    throw request.body;
  }
  //return respu;
}

export async function verificaDuplicadoWeb(datos: string) {
  console.log('datos en verificaduplicidad')
  console.log(datos)
  try {
    let options = { method: 'post', headers: { 'Content-Type': 'application/json' }, body: datos }
    // let url = `http://localhost:3000/api/verificaDuplicadoWeb/`;
    let url = `https://foxservice.net/api/verificaDuplicadoWeb/`;
    let request = await fetch(url, options);
    let respu = ''
    if (request.ok) {
      let contenido = await request.json();
      return contenido;
    } else {
      throw request.body;
    }
    
  } catch (error) {
    console.log('error catch ', error)
    return error
  }
  //return respu;
}