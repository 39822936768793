import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-lcomercial",
  templateUrl: "./lcomercial.component.html",
  styleUrls: ["./lcomercial.component.css"]
})
export class LcomercialComponent implements OnInit {
  tareas: any[] = [
    {
      columA: "Fregado de suelos.",
      columB: "Diario"
    },
    {
      columA: "Limpieza y desinfección de aseos.",
      columB: "Diario"
    },
    {
      columA: "Desempolvado de mobiliario. ",
      columB: "Diario"
    },
    {
      columA: "Limpieza de papeleras y ceniceros (Si existen).",
      columB: "Diario"
    },
    {
      columA: "Limpieza y desinfección de teléfonos (Si existen).",
      columB: "Diario"
    },
    {
      columA: "Repaso de puertas y marcos.",
      columB: "Diario"
    },
    {
      columA: "Limpieza de huellas en cristales de mas uso.",
      columB: "Diario"
    },
    {
      columA: "Cuidado de plantas.",
      columB: "Diario"
    },
    {
      columA: "Lavado de vajillas.",
      columB: "Diario"
    },
    {
      columA: "Lavado de toallas.",
      columB: "Semanal"
    },
    {
      columA: "Suelo de madera: fregado con agua y cera.",
      columB: "Semanal"
    },
    {
      columA: "Limpieza a fondo de puertas y molduras.",
      columB: "Mensual"
    },
    {
      columA: "Limpieza a fondo de mobiliario.",
      columB: "Mensual"
    },
    {
      columA: "Limpieza o fondo de alicatados aseos.",
      columB: "Mensual"
    },
    {
      columA: "Limpieza de puntos de luz.",
      columB: "Trimestral"
    },
    {
      columA: "Suelo de madera: encerado. ",
      columB: "Diario"
    },
    {
      columA: "Suelo de mármol o terrazo: cristalizado.",
      columB: "Anual"
    },
    {
      columA: "Suelo de moqueta: lavado.",
      columB: "Anual"
    },
    {
      columA: "Tapicerías sillas y sillones: lavado.",
      columB: "Anual"
    }
  ];

    public contenido: any;
  
    constructor(private _multilanguage: MultilanguageService){
    }
    ngOnInit(){
      window.scrollTo(0, 0);
      this._multilanguage.obtenerContenido("comercial")
        .then(res => this.contenido = res)
        .catch(err => console.log("error en obtener contenido", err))
    }
}
