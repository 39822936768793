import { Component, OnInit } from '@angular/core';
import { MultilanguageService } from 'app/multilanguage.service';

@Component({
  selector: 'app-sdomestics',
  templateUrl: './sdomestics.component.html',
  styleUrls: ['./sdomestics.component.css']
})
export class SdomesticsComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("domesticos")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }
}
