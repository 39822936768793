import { Routes, RouterModule } from '@angular/router';
import { DelegacionesComponent } from "./delegaciones/delegaciones.component";
import { HomeComponent } from "./home/home.component";
import { ServiciosComponent } from "./servicios/servicios.component";
import { LcomercialComponent } from "./lcomercial/lcomercial.component";
import { LcomunidadesComponent } from "./lcomunidades/lcomunidades.component";
import { GturisticosComponent } from "./gturisticos/gturisticos.component";
import { SlavanderiaComponent } from "./slavanderia/slavanderia.component";
import { SpesteComponent } from "./speste/speste.component";
import { SbanosComponent } from "./sbanos/sbanos.component";
import { SdomesticsComponent } from "./sdomestics/sdomestics.component";
import { SasistencialesComponent } from "./sasistenciales/sasistenciales.component";
import { SacondicionadoComponent } from "./sacondicionado/sacondicionado.component";
import { StextilesComponent } from "./stextiles/stextiles.component";
import { LcristalesComponent } from "./lcristales/lcristales.component";
import { SdomestichorasComponent } from "./sdomestichoras/sdomestichoras.component";
import { SreparacionComponent } from "./sreparacion/sreparacion.component";
import { StelecomunicacionesComponent } from "./stelecomunicaciones/stelecomunicaciones.component";
import { ShotelesComponent } from "./shoteles/shoteles.component";
import { LoginclientesComponent } from "./loginclientes/loginclientes.component";
import { PresupuestoComponent } from "./presupuesto/presupuesto.component";
import { ClientesComponent } from "./clientes/clientes.component";
import { LoginasociadosComponent } from "./loginasociados/loginasociados.component";
import { AsociadosComponent} from "./asociados/asociados.component";
import { CalpresupuestoComponent } from "./calpresupuesto/calpresupuesto.component";
import { SpuntualesComponent } from "./spuntuales/spuntuales.component";
import { SbarcosComponent } from "./sbarcos/sbarcos.component";
import { RecoleccionComponent } from "./recoleccion/recoleccion.component";

const APP_ROUTES: Routes = [
    { path : '', component: HomeComponent },
    { path : 'Delegaciones', component: DelegacionesComponent },
    { path : 'lComercial', component: LcomercialComponent },
    { path : 'lComunidades', component: LcomunidadesComponent },
    { path : 'gTuristicos', component: GturisticosComponent },
    { path : 'sLavanderia', component: SlavanderiaComponent },
    { path : 'sPeste', component: SpesteComponent },
    { path : 'sBanos', component: SbanosComponent },
    { path : 'sDomestics', component: SdomesticsComponent },
    { path : 'sAsistenciales', component: SasistencialesComponent },
    { path : 'sAcondicionado', component: SacondicionadoComponent },
    { path : 'sTextiles', component: StextilesComponent },
    { path : 'lCristales', component: LcristalesComponent },
    { path : 'sDomesticshoras', component: SdomestichorasComponent },
    { path : 'sReparacion', component: SreparacionComponent },
    { path : 'sTelecomunicaciones', component: StelecomunicacionesComponent },
    { path : 'sHoteles', component: ShotelesComponent },
    { path : 'Loginclientes', component: LoginclientesComponent },
    { path : 'Presupuesto', component: PresupuestoComponent },
    { path : 'Clientes', component: ClientesComponent },
    { path : 'Loginasociados', component: LoginasociadosComponent },
    { path : 'Asociados', component: AsociadosComponent },
    { path : 'Calpresupuesto', component: CalpresupuestoComponent },
    { path : 'Spuntuales', component: SpuntualesComponent },
    { path: 'recoleccion', component: RecoleccionComponent },
    { path : 'Sbarcos', component: SbarcosComponent }

];

export const Routing = RouterModule.forRoot(APP_ROUTES);
