export class PresupuestoDatosGenerales {

  public get CodigoPostalIsValid() {
    return /^(?:0[1-9]\d{3}|[1-4]\d{4}|5[0-2]\d{3})$/.test(this.CodigoPostal);
  }

  public get HorasDiaIsValid() {
    return this.HorasDia > 0;
  }

  public get DiasSemanaIsValid() {
    return this.DiasSemana > 0;
  }

  public get RegionIsValid() {
    return !String.IsNullOrWhiteSpace(this.Region);
  }

  public get DatosGeneralesIsValid() {
    return this.CodigoPostalIsValid && this.HorasDiaIsValid && this.DiasSemanaIsValid && this.RegionIsValid;
  }

  public readonly FractionDigits: number = 2;

  public Zona: number;
  public Region: string;
  public HorasDia: number;
  public DiasSemana: number;
  public CodigoPostal: string;
  public PrecioHora: number;
  public PrecioSemanal: number;
  public PrecioMensual: number;

  constructor() {
    this.HorasDia      = undefined;
    this.DiasSemana    = undefined;
    this.CodigoPostal  = undefined;
    this.Region        = undefined;
    this.PrecioHora    = 0;
    this.PrecioSemanal = 0;
    this.PrecioMensual = 0;
  }

  AgregarHoras(Horas: number) {
    this.HorasDia = this.HorasDia || 0;
    this.HorasDia += Horas;

    if (this.HorasDia < 1) {
      this.HorasDia = undefined;
    }
    else if (this.HorasDia > 24) {
      this.HorasDia = 24;
    }
  }

  AgregarDias(Dias: number) {
    this.DiasSemana = this.DiasSemana || 0;
    this.DiasSemana += Dias;

    if (this.DiasSemana < 1) {
      this.DiasSemana = undefined;
    }
    else if (this.DiasSemana > 7) {
      this.DiasSemana = 7;
    }
  }

  /**
   * @description Estable los datos que usa el request
   * @param Presupuesto Objeto destino para copiar los datos del request
   */
  // tslint:disable-next-line: no-shadowed-variable
  SetDatosRequest(Presupuesto: PresupuestoDatosGenerales) {
    this.HorasDia      = Presupuesto.HorasDia;
    this.DiasSemana    = Presupuesto.DiasSemana;
    this.PrecioHora    = Presupuesto.PrecioHora;
    this.CodigoPostal  = Presupuesto.CodigoPostal;
    this.Region        = Presupuesto.Region;
    this.PrecioMensual = Presupuesto.PrecioMensual;
  }
}
