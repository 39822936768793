import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  menu = "inicio";
  router: Router;
  currentUser: any;
  Asociado: any;

  public contenido: any;
  lngSeleccionado: String;
  loading = true;

  bandera: string;

  chatGifUrl =
    "https://firebasestorage.googleapis.com/v0/b/foxcleanimages.appspot.com/o/IMG%2Fonline_chat.gif?alt=media&token=2ed8d389-5fed-4910-8f59-03958096dfbe";
  logourl =
    "https://firebasestorage.googleapis.com/v0/b/foxcleanimages.appspot.com/o/IMG%2Ffoxlogo.jpg?alt=media&token=d9626bff-3e6d-442e-a696-918e1c3a95f7";
  presupuestoImgUrl =
    "https://firebasestorage.googleapis.com/v0/b/foxcleanimages.appspot.com/o/IMG%2Fpresupuesto.gif?alt=media&token=b3d1fba8-2f41-4845-beef-c9d13fabc00e";

  constructor(router: Router, public _multilanguage: MultilanguageService) {
    this.router = router;
  }

  async ngOnInit() {
    console.log('estoy en el ngoninit del header...')
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.Asociado = JSON.parse(localStorage.getItem("Asociado"));

    await this.obtenerContenido();
    this.lngSeleccionado = this._multilanguage.lng;

    switch (this.lngSeleccionado) {
      case "en":
        this.bandera = "../../assets/Img/icons/usa.jpg";
        break;

      case "fr":
        this.bandera = "../../assets/Img/icons/france.jpg";
        break;

      case "pt":
        this.bandera = "../../assets/Img/icons/portugal.jpg";
        break;
      
      case "ne":
        this.bandera = "../../assets/Img/icons/neerland.jpg";
        break;

      case "ge":
        this.bandera = "../../assets/Img/icons/aleman.jpg";
        break;

      case "ru":
        this.bandera = "../../assets/Img/icons/rusia.jpg";
        break;

      case "it":
        this.bandera = "../../assets/Img/icons/italia.jpg";
        break;

      default:
        this.bandera = "../../assets/Img/icons/spain.jpg";
        break;
    }
  }

  cambiarIdioma(lng) {
    this.lngSeleccionado = lng;
    this._multilanguage.cambiarIdioma(this.lngSeleccionado);
    location.reload();
  }

  async obtenerContenido() {
    this._multilanguage.obtenerContenido("menu")
      .then(res => {
        this.contenido = res;
        this.loading = false;
        return res;
      })
      .catch(err => {
        throw err;
      });
  }

  cerrarSesion(): void {
    localStorage.clear();
    this.currentUser = null;
    this.Asociado = null;
    this.router.navigate([""]);
  }

  cambiarMenu(menu) {
    this.menu = menu;
  }
}
