import { Component, OnInit } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { URLSearchParams } from "@angular/http";
import { server } from "../globals";
import { MultilanguageService } from "app/multilanguage.service";
import { NgForm } from '@angular/forms';

@Component({
  selector: "app-presupuesto",
  templateUrl: "./presupuesto.component.html",
  styleUrls: ["./presupuesto.component.css"]
})
export class PresupuestoComponent implements OnInit {
  errores = [
    { mensaje: "" },
    { mensaje: "" },
    { mensaje: "" },
    { mensaje: "" },
    { mensaje: "" },
    { mensaje: "" },
    { mensaje: "" },
  ]
  Empresa = "";
  Nombre = "";
  Apellido = "";
  Ciudad = "";
  Telefono = "";
  Mail = "";
  Peticion = "";

  Validado = false;
  Enviado = false;

  contenido: any;
  constructor(private http: Http, private _multilanguage: MultilanguageService) { }
  ngOnInit() {
    this._multilanguage.obtenerContenido("presupuesto")
      .then(res => {
        this.contenido = res;
        console.log('all data ', this.contenido)
      })
      .catch(err => console.log("error en obtener contenido", err))
  }

  validar(origen: any) {
    let validado = false;
    console.log('origen---->', origen)

    if (origen == 'Empresa' || origen == null) {
      if (this.Empresa.length > 2) {
        console.log('validando Empresa... true')
        validado = true;
        this.errores[0].mensaje = ""
      }
      else {
        console.log('validando Empresa... false')
        validado = false;
        this.errores[0].mensaje = "Falta Empresa";

      }

    }

    if (origen == 'Nombre' || origen == null) {
      if (this.Nombre.length > 2) {
        console.log('validando nombre... true')
        validado = true;
        this.errores[1].mensaje = ""
      }
      else {
        console.log('validando nombre... false')
        validado = false;
        this.errores[1].mensaje = "Falta Nombre";
      }
    }

    if (origen == 'Apellido' || origen == null) {
      if (this.Apellido.length > 2) {
        console.log('validando Apellido... true')
        validado = true;
        this.errores[2].mensaje = ""
      }
      else {
        console.log('validando Apellido... false')
        validado = false;
        this.errores[2].mensaje = "Falta Apellido";
      }

    }

    if (origen == 'Ciudad' || origen == null) {
      if (this.Ciudad.length > 2) {
        console.log('validando Ciudad... true')
        validado = true;
        this.errores[3].mensaje = ""
      }
      else {
        console.log('validando Ciudad... false')
        validado = false;
        this.errores[3].mensaje = "Falta Ciudad";
      }

    }

    if (origen == 'Telefono' || origen == null) {
      if (this.Telefono.length > 2) {
        console.log('validando Telefono... true')
        validado = true;
        this.errores[4].mensaje = ""
      }
      else {
        console.log('validando Telefono... false')
        validado = false;
        this.errores[4].mensaje = "Falta Telefono";
      }
    }

    if (origen == 'Mail' || origen == null) {
      if (this.Mail.length > 2) {
        console.log('validando Mail... true')
        validado = true;
        this.errores[5].mensaje = ""
      }
      else {
        console.log('validando Mail... false')
        validado = false;
        this.errores[5].mensaje = "Falta Mail";
      }
    }

    if (origen == 'Peticion' || origen == null) {
      if (this.Peticion.length > 2) {
        console.log('validando Peticion... true')
        validado = true;
        this.errores[6].mensaje = ""
      }
      else {
        console.log('validando Peticion... false')
        validado = false;
        this.errores[6].mensaje = "Falta Petición";
      }
    }


    this.Validado = validado;
  }
  insertarPresupuesto() {
    this.validar(null);
    if (this.Validado == false) {
      return
    }

    try {
      //let data = new URLSearchParams();
      // data.append("NombreEmpresa", this.Empresa);
      // data.append("NombreContacto", this.Nombre);
      // data.append("ApellidoContacto", this.Apellido);
      // data.append("Ciudad", this.Ciudad);
      // data.append("Telefono", this.Telefono);
      // data.append("Correo", this.Mail);
      // data.append("Peticion", " FOXCLEAN.ES: " + this.Peticion);

      let data = {
        NombreEmpresa: this.Empresa,
        NombreContacto: this.Nombre,
        ApellidoContacto: this.Apellido,
        Ciudad: this.Ciudad,
        Telefono: this.Telefono,
        Correo: this.Mail,
        Peticion: ' FOXCLEAN.ES: ' + this.Peticion,
        Medio: 'PA-WEB',
        Estado: 'PV'
      }
      console.log
      this.http.post(server + "api/Clientes/Peticion", data).subscribe(
        res => {
          this.Empresa = "";
          this.Nombre = "";
          this.Apellido = "";
          this.Ciudad = "";
          this.Telefono = "";
          this.Mail = "";
          this.Peticion = "";
          this.Enviado = true;
        },
        error => {
          alert('Error, verificar')
          console.log(error);
          this.Empresa = "";
        }
      );
    } catch (error) {
      console.log(error)
      alert(error)

    }
  }
}
