import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { URLSearchParams } from "@angular/http";
import { server } from "../globals";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-loginclientes",
  templateUrl: "./loginclientes.component.html",
  styleUrls: ["./loginclientes.component.css"]
})
export class LoginclientesComponent implements OnInit {
  router: Router;
  Usuario = "";
  Clave = "";
  Error = false;

  public contenido: any;
  constructor(router: Router, private http: Http,private _multilanguage: MultilanguageService) {
    this.router = router;
  }

  ngOnInit() {
    this._multilanguage.obtenerContenido("login")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
    let CurrentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (CurrentUser != null) {
      // console.log("logiado");
      this.router.navigate(["Clientes"]);
    }
  }

  clicked() {
    // console.log("Login");
    // console.log(this.Usuario);
    // console.log(this.Clave);

    if (this.Usuario !== "" && this.Clave !== "") {
      let data = {
        Web_User: this.Usuario,
        Web_Pw: this.Clave
      };
      let headers = new Headers({
        "Content-Type": "application/json; charset=utf-8"
      });
      let options = new RequestOptions({ headers: headers });

      this.http.post(server + "api/Clientes/Login", data, options).subscribe(
        res => {
          // console.log(server + "api/Clientes/Login");
          // console.log(data.json());
          let datos: any = res.json();
          if (datos.LogIn) {
            // localStorage.removeItem('Asociados');
            localStorage.clear();
            localStorage.setItem(
              "currentUser",
              JSON.stringify({
                Web_User: this.Usuario,
                Web_Pw: datos.Web_Pw,
                LogIn: datos.LogIn,
                Cif: datos.Cif,
                Cliente_id: datos.Cliente_id,
                E_mail: datos.E_mail,
                Factu_mail: datos.Factu_mail,
                Nombre: datos.Nombre,
                Saldo: datos.Saldo
              })
            );
            this.router.navigate(["Clientes"]);
          } else {
            this.Error = true;
          }
        },
        error => {
          this.Error = true;
          console.log(error.json());
        }
      );
    } else {
      console.log("Complete los campos");
      this.Error = true;
    }
  }
}
