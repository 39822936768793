import { FxZona } from "./fx-zona";
import { IBudgetsByPostalCodes } from "./budgets-by-postal-codes";

export type FxZonaType = {
  [ZonaId: string]: FxZona;
};

export interface PostalCodeSubContratantes {
  [PostalCode: string]: number;
}

export class FxPrecio {

  public static SubContratante: PostalCodeSubContratantes | Object;

  public static readonly SemanasMes: number = 4.34;

  public static readonly PrecioSinSubContratantes: number = 14.5;

  public PrecioHora: number;

  public PrecioSemanal: number;

  public PrecioMensual: number;

  constructor(private Zona: FxZona, private PrecioHoraMinimo: number, private PostalCode: string, private Dias: number, private Horas: number) {
    this.CalcularPrecio();
  }

  private GetPrecioHora(UseSecondValue: boolean): number {
    // Se el precio 2 es del subcontratante.
    // El precio 1 o precio hora minimo, es el precio asociado a código postal segun la "Provincia, Población".
    // let PrecioMinimo = UseSecondValue ? this.Zona.PrecioMinimo2 : this.Zona.PrecioMinimo1;

    let PrecioMinimo = UseSecondValue ? this.Zona.PrecioMinimo2 : this.PrecioHoraMinimo;
    let PrecioDesplazamiento = UseSecondValue ? this.Zona.PrecioDesplazamiento2 : this.Zona.PrecioDesplazamiento1;
    let Resultado1 = this.Zona.PrecioBase * this.Horas;
    let Resultado2 = Resultado1 + PrecioDesplazamiento;
    let PrecioHora = Resultado2 / this.Horas;
    PrecioHora = isFinite(PrecioHora) ? PrecioHora : 0;

    if (PrecioHora < PrecioMinimo) {
      PrecioHora = PrecioMinimo;
    }

    return PrecioHora;
  }

  private CalcularPrecio(): void {
    console.log('Postalcode 0 --->',this.PostalCode)
    console.log('Postalcode hasownproperty --->',FxPrecio.SubContratante.hasOwnProperty(this.PostalCode))
    console.log('SUBCONTRATANTE--->', FxPrecio.SubContratante)
    console.log('OTRA VEZ POSTALCODE--->', FxPrecio.SubContratante[this.PostalCode])

    this.PrecioHora = this.GetPrecioHora(!FxPrecio.SubContratante.hasOwnProperty(this.PostalCode) || FxPrecio.SubContratante[this.PostalCode] < 5);
    this.PrecioSemanal = this.PrecioHora * this.Horas * this.Dias;
    this.PrecioMensual = this.PrecioSemanal * FxPrecio.SemanasMes;
  }
}
