import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-sdomestichoras",
  templateUrl: "./sdomestichoras.component.html",
  styleUrls: ["./sdomestichoras.component.css"]
})
export class SdomestichorasComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("domestico-horas")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }
}
