import { PresupuestoDatosGenerales } from "./presupuesto-datos-generales";

export class Presupuesto extends PresupuestoDatosGenerales {
  public NombreEmpresa: string;
  public NombreContacto: string;
  public ApellidoContacto: string;
  public Ciudad: string;
  public Telefono: string;
  public Correo: string;
  public Peticion: string;
  public Estado: string;
  public Medio: string;

  constructor() {
    super();
    this.Estado = "PV";
    this.Medio = "Web";
  }

  public get NombreEmpresaIsValid() {
    return !String.IsNullOrWhiteSpace(this.NombreEmpresa);
  }

  public get NombreContactoIsValid() {
    return !String.IsNullOrWhiteSpace(this.NombreContacto);
  }

  public get ApellidoContactoIsValid() {
    return !String.IsNullOrWhiteSpace(this.ApellidoContacto);
  }

  public get CiudadIsValid() {
    return !String.IsNullOrWhiteSpace(this.Ciudad);
  }

  public get TelefonoIsValid() {
    return !String.IsNullOrWhiteSpace(this.Telefono)
      && /^([0-9])*$/.test(this.Telefono);
  }

  public get CorreoIsValid() {
    return !String.IsNullOrWhiteSpace(this.Correo)
      && /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.Correo);
  }

  public get PeticionIsValid() {
    return !String.IsNullOrWhiteSpace(this.Peticion);
  }

  public get IsValid() {
    return this.NombreEmpresaIsValid
      && this.NombreContactoIsValid
      && this.ApellidoContactoIsValid
      && this.CiudadIsValid
      && this.TelefonoIsValid
      && this.CorreoIsValid;
      //&& this.PeticionIsValid;
  }
}
