import { Component, OnInit, ViewChild } from '@angular/core';
import { CompleterService, CompleterCmp, CompleterItem, RemoteData } from 'ng2-completer';
import { Http } from '@angular/http';
import { FxPrecio, FxZonaType, PostalCodeSubContratantes } from './precios-calculos';
import { PresupuestoDatosGenerales } from './presupuesto-datos-generales';
import { PresupuestoPdf } from './presupuesto-pdf';
import { server, ToFixed, verificaDuplicadoWeb } from "../globals";
import { MultilanguageService } from 'app/multilanguage.service';
import { IBudgetsByPostalCodes } from './budgets-by-postal-codes';
import Swal from "sweetalert2";


@Component({
  selector: 'app-presupuesto-online',
  templateUrl: './presupuesto-online.component.html',
  styleUrls: ['./presupuesto-online.component.css']
})
export class PresupuestoOnlineComponent implements OnInit {
  public  DesabilitarBotonesPresupuestoComun: boolean;
  public  DesabilitarBotonesPresupuestoPdf: boolean;
  public  PresupuestoPdfActiveForm: boolean;
  public  DatosGenerales: PresupuestoDatosGenerales;
  protected ZipCodeOrCityDataService: RemoteData;
  public  Presupuesto: PresupuestoPdf;
  private PreciosLimpieza: FxZonaType;
  private PrecioHoraMinimo: number;
  private ZonaPrecio: FxPrecio;
  private ZonaId: string;
  public  Loading = true;
  public  Content = {
    titulo:        String.Empty(),
    subtitulo:     String.Empty(),
    subtitulo2_servicios_periodico: String.Empty(),
    obligatorio:   String.Empty(),
    postal:        String.Empty(),
    ciudad:        String.Empty(),
    precio_hora:   String.Empty(),
    total_semanal: String.Empty(),
    total_mensual: String.Empty(),
    invalido:      String.Empty(),
    solicitar:     String.Empty(),
    solicitud:     String.Empty(),
    empresa:       String.Empty(),
    contacto:      String.Empty(),
    apellido:      String.Empty(),
    provincia:     String.Empty(),
    telefono:      String.Empty(),
    email:         String.Empty(),
    extra:         String.Empty(),
    cerrar:        String.Empty(),
    pdf:           String.Empty(),
    enviar:        String.Empty(),
    direccion:     String.Empty(),
    transporte:    String.Empty(),
    materiales:    String.Empty(),
    aspiradora:    String.Empty(),
    dias_semana:   String.Empty(),
    horas_dia:     String.Empty(),
  };

  constructor(private http: Http, private multilanguage: MultilanguageService, private completerService: CompleterService) {
    this.DatosGenerales = new PresupuestoDatosGenerales();
    this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);
    this.DesabilitarBotonesPresupuestoComun = false;
    this.DesabilitarBotonesPresupuestoPdf = false;
    this.PresupuestoPdfActiveForm = false;
    this.RequestValues();
    this.CreateCompleterServices();
    this.GetMultiLanguageContentAsync();
  }

  ngOnInit() {
  }

  async GetMultiLanguageContentAsync() {
    this.multilanguage.obtenerContenido("presupuesto_online")
        .then(Response =>
        {
          this.Content = Response;
          this.Loading = false;
        })
        .catch(Error => {
        });
  }

  async CreateCompleterServices() {
    let CreateUrl = (Query: string, Fields: string[], OrderBy: string[]): string =>
    {
      Query = encodeURIComponent(Query);
      const ApiService = `${server}api/Presupuesto/BudgetsByPostalCodes?`;
      return `${ApiService}Query=${Query}&Fields=${Fields.join(",")}&OrderBy=${OrderBy.join(",")}`;
    };

    this.ZipCodeOrCityDataService = this.completerService.remote(null, "CodigoPostal,Provincia,Poblacion", "CityFullName");
    this.ZipCodeOrCityDataService.urlFormater((Term: any) => {
      return CreateUrl(Term, ["CodigoPostal", "Provincia", "Poblacion"], ["Provincia", "Poblacion", "CodigoPostal"]);
    });
  }

  async RequestValues() {
    this.http.get(`${server}api/Presupuesto/TotalSubConXPostalCode`).subscribe(
      (Value: any) => {
        FxPrecio.SubContratante = JSON.parse(Value._body) as PostalCodeSubContratantes;
      },
      Error => {
        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          html: "Error en obtener información general de precios. <br />Recarge la página nuevamente."
        });
      }
    );

    this.http.get(`${server}api/Presupuesto/PreciosLimpieza`).subscribe(
      (Value: any) => {
        this.PreciosLimpieza = JSON.parse(Value._body) as FxZonaType;
      },
      Error => {
        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          html: "Error en obtener información general de precios. <br />Recarge la página nuevamente."
        });
      }
    );
  }

  CalcularPresupuesto() {
    console.log('calcula pres 128', this.DatosGenerales.CodigoPostal)
    console.log('ZONA', { ...this.PreciosLimpieza[this.ZonaId] })
    console.log('CODPOST', this.DatosGenerales.CodigoPostal.substr(0, 3))
    console.log('PRECIOHORA MIN', this.PrecioHoraMinimo)

    this.ZonaPrecio = new FxPrecio({ ...this.PreciosLimpieza[this.ZonaId] }, this.PrecioHoraMinimo, this.DatosGenerales.CodigoPostal.substr(0, 3), this.DatosGenerales.DiasSemana || 0, this.DatosGenerales.HorasDia || 0);
    this.DatosGenerales.PrecioHora = ToFixed(this.ZonaPrecio.PrecioHora, this.Presupuesto.FractionDigits);
    this.DatosGenerales.PrecioSemanal = ToFixed(this.ZonaPrecio.PrecioSemanal, this.Presupuesto.FractionDigits);
    this.DatosGenerales.PrecioMensual = ToFixed(this.ZonaPrecio.PrecioMensual, this.Presupuesto.FractionDigits);
  }

  @ViewChild("ZipCodeInputCompleter") private ZipCodeInputCompleter: CompleterCmp | undefined;
  @ViewChild("CityInputCompleter") private CityInputCompleter: CompleterCmp | undefined;

  OnZipCodeOrCitySelected(Selected: CompleterItem) {
    if (Selected) {
      this.DatosGenerales.CodigoPostal = Selected.originalObject.CodigoPostal;

      if (this.DatosGenerales.CodigoPostalIsValid && this.DatosGenerales.RegionIsValid) {
        if (this.DatosGenerales.HorasDia>0)
          this.DatosGenerales.HorasDia = this.DatosGenerales.HorasDia
        else
          this.DatosGenerales.HorasDia = 1
          
          
        if (this.DatosGenerales.DiasSemana >0)
          this.DatosGenerales.DiasSemana = this.DatosGenerales.DiasSemana
        else
        this.DatosGenerales.DiasSemana = 1

        this.PrecioHoraMinimo = Selected.originalObject.PrecioHora;
        console.log('selected', Selected.originalObject)
        this.ObtenerZona();
      }
    }
  }

  ObtenerZona() {
    if (this.DatosGenerales.CodigoPostalIsValid && this.DatosGenerales.RegionIsValid) {
      this.ZonaId = this.DatosGenerales.CodigoPostal.substring(2, 3);
      this.DatosGenerales.Zona = parseInt(this.ZonaId);
      this.CalcularPresupuesto();
    }
  }

  MostrarFormularioSolicitud() {
    this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);
    this.Presupuesto.SetDatosRequest(this.DatosGenerales);
    this.PresupuestoPdfActiveForm = false;
    this.ShowModal('#exampleModal');
  }

  async InsertarPresupuesto() {
    const Mensaje = `
    Horas: ${this.DatosGenerales.HorasDia}
    Días a la Semana: ${this.DatosGenerales.DiasSemana}
    Total: ${this.DatosGenerales.PrecioMensual}`;

    this.Presupuesto.Peticion = `FOXCLEAN.ES: ${this.Presupuesto.Peticion || ""}${Mensaje}`;
    this.DesabilitarBotonesPresupuestoComun = true;

    //ABR verificacion para evitar duplicidad
    try {
      let data = {
        "Empresa": this.Presupuesto.NombreEmpresa,
        "Nombre": this.Presupuesto.NombreContacto,
        "Apellido": this.Presupuesto.ApellidoContacto,
        "Ciudad": this.Presupuesto.Ciudad ,
        "Telefono": this.Presupuesto.Telefono,


        "Mail": this.Presupuesto.Correo,
        "Peticion": "PETICION DE PRESUPUESTO PUNTUAL FOXCLEAN.ES \n" +  this.Presupuesto.Peticion,
        "fecha": new Date().toISOString().substr(0, 10)
      }
  
      let datatmp = data
      datatmp['opcion'] = "PresupuestoPeriodico"
      console.log('evaluacion de registro para evitar repetidos y busca trabajo')
      let req = await verificaDuplicadoWeb(JSON.stringify(datatmp))
      //let req = await fetch(server + "api/SendEmail", options)
      console.log('resultado', req)
      //analizando resultado, dependiendo continuamos o cancelamos ejecución
  
      if(req.lookingForAJob>0){
        // alert('Para buscar trabajo hazlo en Bolsa de trabajo.')
        this.CloseModal("#exampleModal1");
        //this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);
        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          showCloseButton: true,
          html: '<b>Para buscar trabajo hazlo en <br />Bolsa de tabajo.</b>'
        });
        return
      }
  
      if(req.rows>0){
        this.CloseModal("#exampleModal1");
        Swal.fire({
          type: 'success',
          title: 'FoxClean',
          showCloseButton: true,
          html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>'
        });
        return
      }
  
      //temporal
      //
    } catch (error) {
      console.log('error en try', error)
    }

    this.http.post(`${server}api/Clientes/Peticion`, this.Presupuesto).subscribe(
      Response => {
        this.CloseModal("#exampleModal");
        this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);

        Swal.fire({
          type: 'success',
          title: 'FoxClean',
          showCloseButton: true,
          // showConfirmButton: true,
          html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>',
          // confirmButtonText: 'Cerrar'
        });

        this.DesabilitarBotonesPresupuestoComun = false;
      },
      Error => {
        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          html: "<b>Error en generar la solicitud.<br />>Inténtalo nuevamente.</b>",
          confirmButtonText: 'Cerrar'
        });

        this.DesabilitarBotonesPresupuestoComun = false;
      }
    );
  }

  ShowModal(ModalId: string): void {
    $(ModalId).modal("show");
  }

  CloseModal(ModalId: string): void {
    $(ModalId).modal("hide");
  }

  MostrarFormularioPresupuestoPdf(Activar: boolean = true) {
    this.PresupuestoPdfActiveForm = Activar;
  }

  async InsertarPresupuestoPdf() {
    const Mensaje = `
    Horas: ${this.DatosGenerales.HorasDia}
    Días a la Semana: ${this.DatosGenerales.DiasSemana}
    Total: ${this.DatosGenerales.PrecioMensual}
    Código Postal: ${this.DatosGenerales.CodigoPostal}
    Provincia: ${this.DatosGenerales.Region}
    Contacto: ${this.Presupuesto.NombreContacto} ${this.Presupuesto.ApellidoContacto}
    Teléfono: ${this.Presupuesto.Telefono}
    Nombre Empresa: ${this.Presupuesto.NombreEmpresa}
    Dirección Empresa: ${this.Presupuesto.DireccionEmpresa}
    Hay una parada de autobus a menos de 20 minutos: ${this.Presupuesto.HayParadaTransportePublicoAMenosDe20Minutos}
    Incluye materiales de limpieza básica: ${this.Presupuesto.IncluyeMaterialesLimpiezaBasica ? "Si" : "No"}
    Incluye aspirador: ${this.Presupuesto.IncluyeAspirador ? "Si" : "No"}`;

    this.Presupuesto.Peticion = `FOXCLEAN.ES: ${this.Presupuesto.Peticion || ""}${Mensaje}`;
    this.DesabilitarBotonesPresupuestoPdf = true;
    this.DesabilitarBotonesPresupuestoComun = true;

    let Request = PresupuestoPdf.GetRequestObject(this.Presupuesto);
    //ABR verificacion para evitar duplicidad

    let data = {
      "Empresa": this.Content.empresa,
      "Nombre": this.Content.contacto,
      "Apellido": this.Content.apellido,
      "Ciudad": this.Content.ciudad,
      "Telefono": this.Content.telefono,
      "Mail": this.Content.email,
      "Peticion": "PETICION DE PRESUPUESTO PUNTUAL FOXCLEAN.ES \n" +  this.Presupuesto.Peticion,
      "fecha": new Date().toISOString().substr(0, 10)
    }

    let datatmp = data
    datatmp['opcion'] = "PresupuestoPeriodico"
    console.log('evaluacion de registro para evitar repetidos y busca trabajo')
    let req = await verificaDuplicadoWeb(JSON.stringify(datatmp))
    //let req = await fetch(server + "api/SendEmail", options)
    console.log('resultado', req)
    //analizando resultado, dependiendo continuamos o cancelamos ejecución
    if(req.lookingForAJob>0){
      // alert('Para buscar trabajo hazlo en Bolsa de trabajo.')
      this.CloseModal("#exampleModal1");
      //this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);
      Swal.fire({
        type: 'error',
        title: 'FoxClean',
        showCloseButton: true,
        html: '<b>Para buscar trabajo hazlo en <br />Bolsa de tabajo.</b>'
      });

      return
    }
    if(req.rows>0){
      this.CloseModal("#exampleModal1");
      Swal.fire({
        type: 'success',
        title: 'FoxClean',
        showCloseButton: true,
        html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>'
      });
      return
    }
    //temporal
    

    //
    this.http.post(`${server}api/Clientes/PeticionPdf`, Request).subscribe(
      Response => {
        this.CloseModal("#exampleModal");
        this.Presupuesto = new PresupuestoPdf(this.DatosGenerales);

        Swal.fire({
          type: 'success',
          title: 'FoxClean',
          showCloseButton: true,
          html: '<b>Muchas gracias por tu solicitud. <br />Te contactaremos pronto.</b>'
        });

        this.DesabilitarBotonesPresupuestoPdf = false;
        this.DesabilitarBotonesPresupuestoComun = false;
      },
      Error => {
        let ErrorMessage: string;

        try {
          ErrorMessage = JSON.parse(Error._body).Message;
        } catch (Ex) {
          ErrorMessage = "<b>Error en generar la solicitud.<br />Inténtalo nuevamente.</b>";
        }

        Swal.fire({
          type: 'error',
          title: 'FoxClean',
          showCloseButton: true,
          html: ErrorMessage
        });

        this.DesabilitarBotonesPresupuestoPdf = false;
        this.DesabilitarBotonesPresupuestoComun = false;
      }
    );
  }
}
