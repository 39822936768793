import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultilanguageService {
  public lng: string;
  public language: any;

  constructor() {
    this.lng = localStorage.getItem("lng") || "es";
  }

  cambiarIdioma(lng){
    this.lng = lng;
    localStorage.setItem("lng", lng);
  }

  async obtenerContenido(componente) {
    // let request = await fetch(`https://backend-foxclean.herokuapp.com/api/multiidioma/foxcleanmultiidioma/${componente}/${this.lng}`);
    console.log('fn multilanguaservice en obtenerContenido------------>',localStorage.getItem("lng"));

    let request = await fetch(`https://foxservice.net/api/multiidioma/foxcleanmultiidioma/${componente}/${this.lng}`);

    if (request.ok) {
      let contenido = await request.json();
      return contenido;
    } else {
        throw request.body;
    }
  }
}
