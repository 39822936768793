import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-sbarcos",
  templateUrl: "./sbarcos.component.html",
  styleUrls: ["./sbarcos.component.css"]
})
export class SbarcosComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("barcos")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }
}
