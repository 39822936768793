import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { DelegacionesComponent } from './delegaciones/delegaciones.component';
import { Routing } from './app.routing';
import { HomeComponent } from './home/home.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { LcomercialComponent } from './lcomercial/lcomercial.component';
import { LcomunidadesComponent } from './lcomunidades/lcomunidades.component';
import { GturisticosComponent } from './gturisticos/gturisticos.component';
import { StextilesComponent } from './stextiles/stextiles.component';
import { SlavanderiaComponent } from './slavanderia/slavanderia.component';
import { SpesteComponent } from './speste/speste.component';
import { SbanosComponent } from './sbanos/sbanos.component';
import { SdomesticsComponent } from './sdomestics/sdomestics.component';
import { SasistencialesComponent } from './sasistenciales/sasistenciales.component';
import { SacondicionadoComponent } from './sacondicionado/sacondicionado.component';
import { LcristalesComponent } from './lcristales/lcristales.component';
import { SdomestichorasComponent } from './sdomestichoras/sdomestichoras.component';
import { SreparacionComponent } from './sreparacion/sreparacion.component';
import { StelecomunicacionesComponent } from './stelecomunicaciones/stelecomunicaciones.component';
import { ShotelesComponent } from './shoteles/shoteles.component';
import { LoginclientesComponent } from './loginclientes/loginclientes.component';
import { PresupuestoComponent } from './presupuesto/presupuesto.component';
import { ClientesComponent } from './clientes/clientes.component';
import { LoginasociadosComponent } from './loginasociados/loginasociados.component';
import { AsociadosComponent } from './asociados/asociados.component';
import { CalpresupuestoComponent } from './calpresupuesto/calpresupuesto.component';
import { SpuntualesComponent } from './spuntuales/spuntuales.component';
import { SbarcosComponent } from "./sbarcos/sbarcos.component";
import { PresupuestoOnlineComponent } from './presupuesto-online/presupuesto-online.component';
import { MultilanguageService } from './multilanguage.service';
import { PresupuestoPuntualComponent } from './presupuesto-puntual/presupuesto-puntual.component';
import { RecoleccionComponent } from './recoleccion/recoleccion.component';
import { HttpClientModule } from '@angular/common/http';
import { Ng2CompleterModule } from 'ng2-completer';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DelegacionesComponent,
    HomeComponent,
    ServiciosComponent,
    LcomercialComponent,
    LcomunidadesComponent,
    GturisticosComponent,
    StextilesComponent,
    SlavanderiaComponent,
    SpesteComponent,
    SbanosComponent,
    SdomesticsComponent,
    SasistencialesComponent,
    SacondicionadoComponent,
    LcristalesComponent,
    SdomestichorasComponent,
    SreparacionComponent,
    StelecomunicacionesComponent,
    ShotelesComponent,
    LoginclientesComponent,
    PresupuestoComponent,
    ClientesComponent,
    LoginasociadosComponent,
    AsociadosComponent,
    CalpresupuestoComponent,
    SpuntualesComponent,
    SbarcosComponent,
    PresupuestoOnlineComponent,
    PresupuestoPuntualComponent,
    RecoleccionComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    Ng2CompleterModule,
    Routing,

  ],
  providers: [
    MultilanguageService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
