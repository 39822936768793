import { Component, OnInit } from "@angular/core";
import { Http, Headers, RequestOptions } from "@angular/http";
import { URLSearchParams } from "@angular/http";
import { server } from "../globals";
import { Router } from "@angular/router";


import { MultilanguageService } from "app/multilanguage.service";
// import { server2 } from "../globals";

@Component({
  selector: "app-clientes",
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.component.css"]
})
export class ClientesComponent implements OnInit {
  Nombre = "";
  fechacre = "";
  Descripcion = "";
  mensajedoc = "";
  mensajefac = "";
  mensajecontratas = "Por favor espere...";
  mensajet = "Por favor espere...";
  mensajef = "Por favor espere...";
  currentUser = JSON.parse(localStorage.getItem("currentUser"));
  CuentaCliente;
  ContrataCliente;
  Documentos;
  Facturas;
  ccontratas = false;
  cargoFactura = false;
  cargoDoc = false;
  descargado = false;

  QuejaEnviada = false;
  ErrorQueja = false;

  router: Router;

  public contenido: any;

  constructor(private http: Http, router: Router ,private _multilanguage: MultilanguageService) {
    this.router = router;
  }

  ngOnInit() {
    this._multilanguage.obtenerContenido("clientes")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
    // console.log(this.currentUser);
    let cuentaCliente = {
      Web_User: this.currentUser.Web_User,
      Cif: this.currentUser.Cif
    };

    this.http.post(server + "api/Clientes/Cuenta", cuentaCliente).subscribe(
      data => {
        this.CuentaCliente = data.json();
        // console.log(this.CuentaCliente);
      },
      error => {
        console.log("Error");
      }
    );

    let contrataCliente = {
      Web_User: this.currentUser.Web_User,
      Web_Pw: this.currentUser.Web_Pw
    };
    this.http.post(server + "api/Clientes/Contrata", contrataCliente).subscribe(
      data => {
        this.ContrataCliente = data.json();
        console.log(this.ContrataCliente);
        this.ccontratas = true;
      },
      error => {
        console.log("Error");
      }
    );

    let headers = new Headers({
      "Content-Type": "application/json; charset=utf-8"
    });
    let options = new RequestOptions({ headers: headers });
    let datad = {
      id: this.currentUser.Cliente_id,
      tipo: "CLI",
      directorio: "documentos"
    };
    this.http.post(server + "api/Cliente/listare", datad, options).subscribe(
      res => {
        console.log("si_D");
        console.log(JSON.stringify(res.json()));
        this.Documentos = res.json();

        if (
          JSON.stringify(res.json()) ===
          JSON.stringify([{ Nombre: "no", Ruta: "no", fechacre: null }])
        ) {
          this.mensajet = "No hay documentos.";
        } else {
          this.cargoDoc = true;
        }
      },
      error => {
        console.log("no_D");
        console.log("Error");
        this.mensajet = "Error, intentelo mas tarde.";
      }
    );

    let data2 = {
      id: this.currentUser.Cliente_id,
      tipo: "CLI",
      directorio: "facturas"
    };

    this.http.post(server + "api/Cliente/listare", data2, options).subscribe(
      res => {
        console.log("si_F");
        this.Facturas = res.json();
        if (
          JSON.stringify(res.json()) ===
          JSON.stringify([{ Nombre: "no", Ruta: "no", fechacre: null }])
        ) {
          this.mensajef = "No hay Facturas.";
        } else {
          this.cargoFactura = true;
        }
      },
      error => {
        console.log("Error");
        console.log("no_F");
        this.mensajef = "Error, intentelo mas tarde.";
      }
    );
  }

  cerrarSesion(): void {
    localStorage.clear();
    this.currentUser = null;
    // this.Asociado = null;
    this.router.navigate(["Loginclientes"]);
  }

  descargarFile(Ruta: string, Nombre: string) {
    console.log(Ruta);
    this.http
      .post(server + "api/Archivos/Descargar", { Ruta: Ruta, Nombre: Nombre })
      .subscribe(
        data => {
          console.log(data);
          this.descargado = true;
        },
        error => {
          console.log(error.json());
        }
      );
  }

  EnviarQueja() {
    if (this.Nombre === "" || this.Descripcion === "") {
      this.ErrorQueja = true;
    } else {
      console.log(this.Nombre);
      console.log(this.Descripcion);
      let data = { Nombre: this.Nombre, Descripción: this.Descripcion };
      this.http.post(server + "api/Clientes/Queja", data).subscribe(
        res => {
          // console.log(res);
          this.QuejaEnviada = true;
          this.ErrorQueja = false;
          this.Nombre = "";
          this.Descripcion = "";
        },
        error => {
          console.log("Error");

          this.QuejaEnviada = false;
          this.ErrorQueja = true;
          this.Nombre = "";
          this.Descripcion = "";
        }
      );
    }
  }
}
