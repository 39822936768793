import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-lcomunidades",
  templateUrl: "./lcomunidades.component.html",
  styleUrls: ["./lcomunidades.component.css"]
})
export class LcomunidadesComponent implements OnInit {
  tareas: any[] = [
    {
      columA: "Fregado de suelos de portal y ascensores.",
      columB: "Diario"
    },
    {
      columA: "Fregado de escalera y descansillos.",
      columB: "Semanal"
    },
    {
      columA: "Desempolvado de mobiliario barandillas puertas. ",
      columB: "Diario"
    },
    {
      columA: "Limpieza de papeleras y ceniceros (Si existen).",
      columB: "Diario"
    },
    {
      columA: "Limpieza de huellas en cristales de mas uso.",
      columB: "Diario"
    },
    {
      columA: "Cuidado de plantas.",
      columB: "Diario"
    },
    {
      columA: "Limpieza de ascensores.",
      columB: "Diario"
    },
    {
      columA: "Limpieza a fondo de mobiliario.",
      columB: "Mensual"
    },
    {
      columA: "Limpieza de cristales.",
      columB: "Mensual"
    },
    {
      columA: "Cambio lámparas (material por cuenta del cliente).",
      columB: "Mensual"
    },
    {
      columA: "Limpieza de puntos de luz.",
      columB: "Trimestral"
    },
    {
      columA: "Limpieza de paredes y techos.",
      columB: "Trimestral"
    },
    {
      columA: "Suelo de mármol o terrazo: cristalizado.",
      columB: "Anual"
    }
  ];


  public contenido: any;

  constructor(private _multilanguage: MultilanguageService) {
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("comunidades")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }
}
