import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';

import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: 'app-recoleccion',
  templateUrl: './recoleccion.component.html',
  styleUrls: ['./recoleccion.component.css']
})
export class RecoleccionComponent implements OnInit {
  public contenido: any;
  constructor(private _multilanguage: MultilanguageService, private http: Http,){
  }

  ngOnInit() {
    this._multilanguage.obtenerContenido("recoleccion")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))

    
  }

}
