import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-stelecomunicaciones",
  templateUrl: "./stelecomunicaciones.component.html",
  styleUrls: ["./stelecomunicaciones.component.css"]
})
export class StelecomunicacionesComponent implements OnInit {
  public contenido: any;
  
  constructor(public _multilanguage: MultilanguageService) {

  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("telecomunicacions")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }


}
