import { ToFixed } from "app/globals";
import { Presupuesto } from "./presupuesto";
import { PresupuestoDatosGenerales } from "./presupuesto-datos-generales";

export class PresupuestoPdf extends Presupuesto {

  public get IncluyeMaterialesLimpiezaBasica(): boolean {
    return this._IncluyeMaterialesLimpiezaBasica;
  }

  public set IncluyeMaterialesLimpiezaBasica(Value: boolean) {
    this._IncluyeMaterialesLimpiezaBasica = Value;
    this.CalcularPrecios();
  }

  public get IncluyeAspirador(): boolean {
    return this._IncluyeAspirador;
  }

  public set IncluyeAspirador(Value: boolean) {
    this._IncluyeAspirador = Value;
    this.CalcularPrecios();
  }

  public get DireccionEmpresaIsValid() {
    return !String.IsNullOrWhiteSpace(this.DireccionEmpresa);
  }

  public get HayParadaTransportePublicoAMenosDe20MinutosIsValid() {
    return ["Si", "No"].find(Item => Item === this.HayParadaTransportePublicoAMenosDe20Minutos) !== undefined;
  }

  public get IsValid2() {
    return this.IsValid
      && this.DireccionEmpresaIsValid
      && this.HayParadaTransportePublicoAMenosDe20MinutosIsValid;
  }

  /**
   * @description 4% (Precio Menual)
   */
  public static readonly PorcentajeMaterialesLimpiezaBasica: number = 0.04;

  /**
   * @description 8.33 euros sumados al precio mensual
   */
  public static readonly MontoMensualPorUsoAspirador: number = 8.33;

  private _IncluyeMaterialesLimpiezaBasica: boolean;
  private _IncluyeAspirador: boolean;
  public DireccionEmpresa: string;
  public HayParadaTransportePublicoAMenosDe20Minutos: string;
  public PrecioMaterialesLimpiezaBasica: number;
  public PrecioAspirador: number;

  // tslint:disable-next-line:no-shadowed-variable
  public static GetRequestObject(Presupuesto: PresupuestoPdf) {
    return {
      ...Presupuesto,
      IncluyeMaterialesLimpiezaBasica: Presupuesto.IncluyeMaterialesLimpiezaBasica,
      IncluyeAspirador: Presupuesto.IncluyeAspirador
    };
  }

  // tslint:disable-next-line: no-shadowed-variable
  constructor(public Presupuesto: PresupuestoDatosGenerales) {
    super();
    this.Medio = "Google";
  }

  private AplicarMaterialesLimpieza(Value: boolean, PrecioMensual: number) {
    if (Value) {
      this.PrecioMaterialesLimpiezaBasica = ToFixed(PrecioMensual * PresupuestoPdf.PorcentajeMaterialesLimpiezaBasica, this.FractionDigits);
      this.PrecioMensual = PrecioMensual + this.PrecioMaterialesLimpiezaBasica;
    }
    else {
      this.PrecioMaterialesLimpiezaBasica = 0;
      this.PrecioMensual = PrecioMensual;
    }
  }

  private AplicarAspirador(Value: boolean, PrecioMensual: number) {
    if (Value) {
      this.PrecioAspirador = PresupuestoPdf.MontoMensualPorUsoAspirador;
      this.PrecioMensual = PrecioMensual + this.PrecioAspirador;
    }
    else {
      this.PrecioAspirador = 0;
      this.PrecioMensual = PrecioMensual;
    }
  }

  private AplicarCargoExtra(Materiales: boolean, Aspirador: boolean, PrecioMensual: number) {
    this.AplicarAspirador(Aspirador, PrecioMensual);
    this.AplicarMaterialesLimpieza(Materiales, this.PrecioMensual);
    this.PrecioMensual = ToFixed(this.PrecioMensual, this.FractionDigits);
  }

  private CalcularPrecios() {
    this.PrecioHora = ToFixed(this.Presupuesto.PrecioHora, this.FractionDigits);
    this.AplicarCargoExtra(this.IncluyeMaterialesLimpiezaBasica, this.IncluyeAspirador, this.Presupuesto.PrecioMensual);
  }
}
