import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { URLSearchParams } from "@angular/http";
import { server } from "../globals";

import { MultilanguageService } from "app/multilanguage.service";
// import {server2} from "../globals";

@Component({
  selector: "app-asociados",
  templateUrl: "./asociados.component.html",
  styleUrls: ["./asociados.component.css"]
})
export class AsociadosComponent implements OnInit {
  router: Router;
  Nombre = "";
  Descripcion = "";
  QuejaEnviada = false;
  ErrorQueja = false;
  Documentos;
  descargado = false;
  mensajed = "Espere por favor...";
  mensajef = "Espere por favor...";

  cargoFactura = false;
  cargoDoc = false;

  CurrentAsociado = JSON.parse(localStorage.getItem("Asociado"));
  CuentaAsociado = "";
  ContrataSocio = "";
  Facturas;

  public contenido: any;

  constructor(router: Router, private http: Http, private _multilanguage: MultilanguageService) {
    this.router = router;
  }

  ngOnInit() {
    // sub documentos
    this._multilanguage.obtenerContenido("asociados")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
      
    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });
    let valores2 = {
      id: this.CurrentAsociado.Numero,
      tipo: "SUB",
      directorio: "documentos"
    };
    this.http.post(server + "api/Cliente/listare", valores2, options).subscribe(
      res => {
        // console.log(data.json());
        this.Documentos = res.json();
        if (
          JSON.stringify(res.json()) ===
          JSON.stringify([{ Nombre: "no", Ruta: "no", fechacre: null }])
        ) {
          this.mensajed = "No hay documentos.";
        } else {
          this.cargoDoc = true;
        }
      },
      error => {
        console.log(error.json());
        this.mensajed = "Error, intentelo mas tarde.";
      }
    );

    // sub facturas
    let valores3 = {
      id: this.CurrentAsociado.Numero,
      tipo: "SUB",
      directorio: "facturas"
    };
    this.http.post(server + "api/Cliente/listare", valores3, options).subscribe(
      data2 => {
        console.log(data2.json());
        this.Facturas = data2.json();
        if (
          JSON.stringify(data2.json()) ===
          JSON.stringify([{ Nombre: "no", Ruta: "no", fechacre: null }])
        ) {
          this.mensajef = "No hay facturas.";
        } else {
          this.cargoFactura = true;
        }
      },
      error => {
        console.log("Error");
        this.mensajef = "Error, intentelo mas tarde.";
      }
    );

    // console.log(this.CurrentAsociado.Web_User);
    let data = {
      Web_User: this.CurrentAsociado.Web_User,
      Cif: this.CurrentAsociado.Cif
    };
    this.http.post(server + "api/Asociados/Cuenta", data).subscribe(
      res => {
        this.CuentaAsociado = res.json();
        // console.log(this.CuentaAsociado);
      },
      error => {
        console.log("Error");
      }
    );
    let datoSocio = {
      Web_User: this.CurrentAsociado.Web_User,
      Web_Pw: this.CurrentAsociado.WebPw
    };
    this.http.post(server + "api/Socios/Contrata", datoSocio).subscribe(
      res => {
        this.ContrataSocio = res.json();
        // console.log(this.ContrataSocio);
      },
      error => {
        console.log("Error");
      }
    );
  }

  descargarFile(Ruta: string, Nombre: string) {
    // console.log(Ruta);
    this.http
      .post(server + "api/Archivos/Descargar", { Ruta: Ruta, Nombre: Nombre })
      .subscribe(
        data => {
          // console.log(data);
          this.descargado = true;
        },
        error => {
          console.log("Error");
        }
      );
  }

  cerrarSesion(): void {
    localStorage.clear();
    // this.currentUser = null;
    this.CurrentAsociado = null;
    this.router.navigate(["Loginasociados"]);
  }

  EnviarQueja() {
    if (this.Nombre === "" || this.Descripcion === "") {
      this.ErrorQueja = true;
    } else {
      // console.log(this.Nombre);
      // console.log(this.Descripcion);
      let data = { Nombre: this.Nombre, Descripcion: this.Descripcion };
      this.http.post(server + "api/Clientes/Queja", data).subscribe(
        res => {
          // console.log(res);
          this.QuejaEnviada = true;
          this.ErrorQueja = false;
          this.Nombre = "";
          this.Descripcion = "";
        },
        error => {
          console.log("Error");

          this.QuejaEnviada = false;
          this.ErrorQueja = true;
          this.Nombre = "";
          this.Descripcion = "";
        }
      );
    }
  }
}
