import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-lcristales",
  templateUrl: "./lcristales.component.html",
  styleUrls: ["./lcristales.component.css"]
})
export class LcristalesComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("cristales")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }
}
