import { Component, OnInit } from '@angular/core';
import { MultilanguageService } from 'app/multilanguage.service';

@Component({
  selector: 'app-slavanderia',
  templateUrl: './slavanderia.component.html',
  styleUrls: ['./slavanderia.component.css']
})
export class SlavanderiaComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("lavanderia")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }

}
