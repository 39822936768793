import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';

@Component({
  selector: 'app-calpresupuesto',
  templateUrl: './calpresupuesto.component.html',
  styleUrls: ['./calpresupuesto.component.css']
})
export class CalpresupuestoComponent implements OnInit {

  constructor(private http:Http) { }

  ngOnInit() {
  }
      Poblacion:string="";
      Ptrabajo:string="";
      Sanitarios:string="";
      Ventanas:string="";
      Dsemana:number;
      Resultado:string;

  calcularpres()
  {
    var calculo =0.00;
    var prhora=9;
    var desplazamiento=3;
    var dias=2;
    var horasdia=3;
    var preciohora=0.00;
    var mensualidad=0.00;
   // let calculo:number=0.0
    
    preciohora=((prhora*horasdia)+desplazamiento)/horasdia;
    dias=this.Dsemana;
    mensualidad = (horasdia * dias * 4.34 * preciohora)
 //   this.Resultado=String(mensualidad);

    //this.Resultado=calculo;
    
console.log(String(mensualidad));
document.getElementById("Resultado").textContent=String(mensualidad);



  }

}
