import { Component, OnInit } from "@angular/core";
import { MultilanguageService } from "app/multilanguage.service";


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})

export class HomeComponent implements OnInit {
  public contenido: any;
  public verPuntual = false;

  public color1 = "#ff802b";
  public color2 = "#aaa";

  constructor(private _multilanguage: MultilanguageService) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("inicio")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }

  mostrarPuntual() {
    this.color2='#ff802b'; this.color1='#aaa'; this.verPuntual=true;
  }

  mostrarMensual() {
    this.color1 = '#ff802b'; this.color2 = '#aaa'; this.verPuntual = false;
  }
}
