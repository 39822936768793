import { Component, OnInit } from '@angular/core';
import { MultilanguageService } from "app/multilanguage.service";
import { Http } from '@angular/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent implements OnInit{
  public contenido: any;
  title = 'app works!';
  whatsappNumber: string

  constructor(private _multilanguage: MultilanguageService, private http: Http,){
  }

  ngOnInit() {
    this._multilanguage.obtenerContenido("inicio")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))

    this.RequestValues();
  }
 
  async RequestValues() {
    // this.http.get(`https://backend-foxclean.herokuapp.com/api/numeroWhatsapp/foxcleanes`).subscribe(
    this.http.get(`https://foxservice.net/api/numeroWhatsapp/foxcleanes`).subscribe(
      (Value: any) => {
        let n = Value._body.replace(/['"]+/g, '')
        this.whatsappNumber = `https://wa.me/${n}`
      },
      Error => {
        console.log('error', Error)
      }
    );
  }
}
