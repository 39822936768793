import { Component, OnInit } from '@angular/core';
import { MultilanguageService } from 'app/multilanguage.service';

@Component({
  selector: 'app-speste',
  templateUrl: './speste.component.html',
  styleUrls: ['./speste.component.css']
})
export class SpesteComponent implements OnInit{
  public contenido: any;

  constructor(private _multilanguage: MultilanguageService){
  }
  ngOnInit(){
    window.scrollTo(0, 0);
    this._multilanguage.obtenerContenido("pest")
      .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
  }


}
