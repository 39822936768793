import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Http, Headers, RequestOptions } from "@angular/http";
import { URLSearchParams } from "@angular/http";
import { server } from "../globals";


import { MultilanguageService } from "app/multilanguage.service";

@Component({
  selector: "app-loginasociados",
  templateUrl: "./loginasociados.component.html",
  styleUrls: ["./loginasociados.component.css"]
})
export class LoginasociadosComponent implements OnInit {
  router: Router;
  Usuario = "";
  Clave = "";
  Error = false;

  public contenido: any;
  constructor(router: Router, private http: Http, private _multilanguage: MultilanguageService) {
    this.router = router;
  }

  ngOnInit() {
    this._multilanguage.obtenerContenido("login_asociados")
    .then(res => this.contenido = res)
      .catch(err => console.log("error en obtener contenido", err))
    let CurrentAsociado = JSON.parse(localStorage.getItem("Asociado"));
    if (CurrentAsociado != null) {
      // console.log("logiado");
      this.router.navigate(["Asociados"]);
    }
  }

  clicked() {
    // console.log("Login");
    // console.log(this.Usuario);
    // console.log(this.Clave);

    let headers = new Headers({ "Content-Type": "application/json" });
    let options = new RequestOptions({ headers: headers });

    if (this.Usuario !== "" && this.Clave !== "") {
      let data = {
        Web_User: this.Usuario,
        Web_Pw: this.Clave
      };
      this.http
        .post(server + "api/Clientes/LoginSocios", data, options)
        .subscribe(
          res => {
            // console.log(res.json());
            let datos: any = res.json();
            if (datos.LogIn) {
              // localStorage.removeItem('currentUser');
              localStorage.clear();
              localStorage.setItem(
                "Asociado",
                JSON.stringify({
                  Numero: datos.Numero,
                  LogIn: datos.LogIn,
                  Nombre: datos.Nombre,
                  ApellidoUno: datos.ApellidoUno,
                  ApellidoDos: datos.ApellidoDos,
                  Saldo: datos.Saldo,
                  Cuenta: datos.Cuenta,
                  Delegacion: datos.Delegacion,
                  Dni: datos.Dni,
                  Web_User: datos.Web_User,
                  WebPw: datos.WebPw,
                  Cif: datos.Cif
                })
              );
              this.router.navigate(["Asociados"]);
            } else {
              this.Error = true;
            }
          },
          error => {
            this.Error = true;
            console.log(error.json());
          }
        );
    } else {
      console.log("Complete los campos");
      this.Error = true;
    }
  }
}
